import React from 'react';

import Layout from '../components/Layout';
import Band from '../components/Band/Band';
import { Container } from '../components/Grid/Grid';
import SEO from '../components/SEO';

const NotFoundPage = () => (
    <Layout>

        <SEO title="Not Found" />

        <Band size="lg">
            <Container>

                <h1>Not Found</h1>
                <p>The page you were looking for has not been found.</p>

            </Container>
        </Band>

    </Layout>
);

export default NotFoundPage;
